import React from 'react'
import fetch from 'cross-fetch'
import Recaptcha from 'react-google-recaptcha'
import { Check } from 'react-feather'

import { logEvent } from '../utils/analytics'

import Button from './button'
import RadioButton from './radioButton'

import addToMailchimp from 'gatsby-plugin-mailchimp'

const ColumnControl = 'inline-block relative w-full'
const ColumnClass = 'grid grid-col-1 lg:grid-cols-2 lg:gap-4'
const Control = 'inline-block relative w-full mb-4'
const Input = 'inline-block relative w-full h-12 px-4 py-2 bg-brand-primary text-white border-white border-4 placeholder-white'

const recaptchaRef = React.createRef();

class Sales extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      recaptcha: false,
      submitted: false,
      firstname: '',
      lastname: '',
      
      message: '',
      
      email: '',
      brand: '',
      // questions
     
      //pain points
      PainPoints: [
        {value: false,
          name: 'Amazon'},
        {value: false,
        name: 'Fresh'},
        {value: false,
        name: 'Instacart'},
        {value: false,
        name: 'Retail eCommerce Platform'},
        {
          value: false,
          name: 'Dashboard Access'
        }
      ],
      subscribe: false
     

      
    }

    this.handleCaptcha = this.handleCaptcha.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleCaptcha(value) {
    if (value) {
      this.setState({ ...this.state, recaptcha: true })
    }
  }
  
  toggleButton(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    
  }
  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
    
    this.toggleButton(e)
    
    
  }

  


  handleSubmit(event) {
    event.preventDefault()

    const recaptchaValue = recaptchaRef.current.getValue();
    const form = event.target
    console.log(this.state)
    if (this.state.recaptcha) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: this.encode({
          'form-name': form.getAttribute('name'),
          'g-recaptcha-response': recaptchaValue,
          ...this.state
        })
      })
        .then(() => {
          const object = {
            category: 'Contact',
            action: 'Submitted Contact Form'
          }
          logEvent(object)
          this.setState({
            submitted: true,
            firstname: '',
            lastname: '',
           
            message: '',
            
            email: '',

            brand: '',
            
            //pain points
            PainPoints: [
              {value: false,
                name: 'Amazon'},
              {value: false,
              name: 'Amazon Fresh'},
              {value: false,
              name: 'Instacart'},
              {value: false,
              name: 'Retail eCommerce Platform'},
              {
                value: false,
                name: 'Dashboard Access'
              }
            ],
            subscribe: false
          })
          if (this.state.subscribe === true) {
            addToMailchimp(this.state.email)
          }
        })
        .catch(error => window.alert(error))

        addToMailchimp(this.state.email)
    }
  }

  
  render() {
    const { submitted } = this.state
   

    return (
      <div className="inline-block relative w-full">
        <div className="block relative center m-auto">
          {submitted ? (
            <div className="inline-block relative w-full bg-green-900 p-12">
              <Check
                className="w-12 h-12 bg-green-500 rounded-full mb-8 mx-auto p-2"
                color="white"
              />
              <strong className="inline-block relative w-full text-white mb-4">
                We'll be in touch!
              </strong>
              <p className="inline-block relative w-full text-sm text-gray-400">
                We'll get back to you within 1-2 business days.
              </p>
            </div>
          ) : (
            <form
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              data-netlify-recaptcha="true"
              className="inline-block relative w-full"
              onSubmit={this.handleSubmit}
              method="post"
              name="contact"
             
            >
              {/* HIDDEN FOR NETLIFY */}
              <input type="hidden" name="form-name" value="contact" />
              {/* FORM */}
              <div className={ColumnClass}>
                <div className={ColumnControl}>
                  {/* FNAME */}
                  <div className={Control}>
                   
                    <input
                      placeholder="Your first name"
                      className={Input}
                      onChange={this.handleChange}
                      type="text"
                      name="firstname"
                      value={this.state.firstname}
                      required
                    />
                  </div>
                </div>
                <div className={ColumnControl}>
                  {/* LNAME */}
                  <div className={Control}>
                   
                    <input
                      placeholder="Your last name"
                      className={Input}
                      onChange={this.handleChange}
                      type="text"
                      name="lastname"
                      value={this.state.lastname}
                      required
                    />
                  </div>
                </div>
              </div>
              
                  {/* EMAIL */}
                  <div className={Control}>
                   
                    <input
                      placeholder="Your work email"
                      className={Input}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={this.state.email}
                      required
                    />
                  </div>

                  <div className={Control}>
                   
                    <input
                      placeholder="Your Brand or Company"
                      className={Input}
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={this.state.email}
                      required
                    />
                  </div>
              
             
              
              
                <textarea
                  placeholder="Add a message (optional)"
                  className='rounded-3xl inline-block relative w-full h-40 px-4 py-2 bg-brand-primary text-white border-4 placeholder-white'
                  onChange={this.handleChange}
                  name="message"
                  type="text"
                  value={this.state.message}
                />
              <div className="flex flex-col mb-10">
                <label className="font-bold text-3xl text-left my-10">Platforms of Interest</label>
                <div className='flex flex-col lg:flex-row'>
                  <div className=' mr-4'>

                    {this.state.PainPoints.map(painpoint => {
                    return (<RadioButton 
                        onClick={this.toggleButton}
                        handleChange={this.handleChange}
                        checked={painpoint.value}
                        name={`${painpoint.name}`}
                        value={painpoint.value}
                        text={`${painpoint.name}`}
                      />)
                    })}
                      </div>

                </div>
                

              </div>

              <div>
                    <RadioButton 
                        onClick={this.toggleButton}
                        checked={this.state.subscribe}
                        handleChange={this.handleChange}
                        name="subscribe"
                        text="Subscribe to our Newsletter"
                        value={this.state.subscribe}
                      
                      />
              </div>
              {/* RECAPTCHA */}
              <div className={Control}>
                <Recaptcha
                  onChange={this.handleCaptcha}
                  sitekey="6Lc1X3ggAAAAAPxEcJkwFiEwWkr9gO81fIYnURo_"
                  ref={recaptchaRef}
                  id="recaptcha-google"
                />
              </div>
              <Button primary outlined full text="Submit Form" type="submit" />
            </form>
          )}
        </div>
      </div>
    )
  }
}

export default Sales
