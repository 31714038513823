import React from 'react'


const RadioButton = ({handleChange, name, onClick, value, text}) => {
    return (
        <div className="flex my-4 uppercase align-start justify-start">
                <div className="mr-10">  
                    <input 
                        type="checkbox"
                        // checked={value}       
                        onChange={handleChange}
                        name={name}
                        value={value}
                    ></input>
                </div>
            <label className='font-semibold flex items-center capitalize relative text-left text-lg  mb-4' htmlFor={`${name}`}>
                  {text}
            </label>
             </div>

        )
}

export default RadioButton